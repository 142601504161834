import React from 'react';
import Layout from '../components/layout';
import Loading from '../components/loading';

const RegisterPage = () => (
    <Layout>
        <div className="page-wrapper">
            <Loading />
        </div>
    </Layout>
);

export default RegisterPage;
