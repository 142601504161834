import React from 'react';
import { Container } from 'react-bootstrap';
import './style.scss';

const Loading = () => (
    <Container fluid className="section spinner-container">
        <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </Container>
);

export default Loading;
